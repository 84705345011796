<template>
  <router-view />
</template>

 
<style>
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: auto;
}

@font-face {
  font-family: "Microsoft YaHei";
  font-style: normal;
  font-weight: normal;
  src: url("/src/assets/msyh.ttf");
}

* {
  box-sizing: border-box;
  text-decoration: none;
  margin: 0;
  padding: 0;
  font-family: "Microsoft YaHei", sans-serif;
}

a {
  color: #fcfbfc;
}

a:hover {
  transition: all 0.6s;
  color: #08b3ff;
  cursor: pointer;
}

ul {
  padding: 0px;
  margin: 0px;
}

#app {
  height: 100%;
}
</style>