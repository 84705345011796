import { createStore } from "vuex";

export default createStore({
  state: {
    count: 0,
  },
  getters: {},
  mutations: {
    updataCount(state: any, counts) {
      state.count = counts;
      console.log(state.count);
    },
  },
  actions: {
    TopupdataCount(cxt, val) {
      cxt.commit("updataCount", val);
    },
  },
  modules: {},
});
