import { createApp } from "vue";
import App from "./App.vue";
import router from "./router/index";
import "../public/js/rem";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import metaInfo from "vue-meta-info";
import VueLazyload from "vue-lazyload";
import "./assets/font_4110892_zcvvh8ui7xh/iconfont.css";
import store from "./store";
createApp(App)
  .use(router)
  .use(ElementPlus)
  .use(metaInfo)
  .use(VueLazyload)
  .use(store)
  .mount("#app");
