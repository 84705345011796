import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/HomeView.vue"),
  },
  {
    path: "/news",
    name: "news",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
    children: [
      {
        path: "Top18",
        name: "Top18",
        component: () => import("../components/NewPage1.vue"),
      },
      {
        path: "GoldAward",
        name: "GoldAward",
        component: () => import("../components/NewPage2.vue"),
      },
      {
        path: "SecondPrize",
        name: "new3",
        component: () => import("../components/NewPage3.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
